$type-14: (14px, $baseline-x2);

$type-18: (18px, $baseline-x3);

$type-20: (
  null: (
    18px,
    $baseline-x3
  ),
  sm: (
    20px,
    $baseline-x4
  )
);

$type-22: (22px, $baseline-x4);

$type-26: (
  null: (
    22px,
    $baseline-x5
  ),
  sm: 26px
);

$type-35: (
  null: (
    28px,
    $baseline-x4
  ),
  sm: (
    35px,
    $baseline-x5
  )
);
