$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);

$color-grey: rgb(171, 177, 189);
$color-grey-light: rgb(245, 247, 250);

$color-white: rgb(255, 255, 255);

$color-blue: rgb(92, 140, 230);
$color-blue-light: rgb(71, 124, 223);
