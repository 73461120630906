$iota-components-social-size: 24px;

.c-social__link {
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 0;
}

.c-social__icon {
  width: $iota-components-social-size;
  height: $iota-components-social-size;
}
