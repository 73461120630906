.c-page-header {
  background: $color-grey-light;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 400px;

  @include iota-breakpoint(sm) {
    height: 500px;
  }
}

.c-page-header__figure {
  position: absolute;
  bottom: -22px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  @include iota-breakpoint(sm) {
    bottom: -32px;
  }
}

.c-page-header__asset {
  width: 300px;

  @include iota-breakpoint(sm) {
    width: 350px;
  }
}
