.c-page-section {
  padding: 70px 0;
  text-align: center;

  @include iota-breakpoint(sm) {
    padding: 110px 0;
  }
}

.c-page-section__description {
  color: #7d828b;
}

.c-page-section--projects {
  padding: 0;
}

.c-page-section--bio {
  padding-bottom: 0;
}

.c-logo {
  display: block;
}

.c-logo--nike {
  width: 79px;
  height: 29px;
}

.c-logo--salesforce {
  width: 63px;
  height: 44px;
}

.c-logo--condenast {
  width: 159px;
  height: 29px;
}

.c-logo--chipotle {
  width: 50px;
  height: 49px;
}

.c-logo--vanityfair {
  width: 98px;
  height: 22px;
}

.c-logo--apple {
  width: 44px;
  height: 61px;
}

.c-logo--ikea {
  width: 90px;
  height: 16px;
}
