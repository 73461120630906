$iota-objs-container-gutter-default : $baseline-x3;
$iota-objs-container-size-default   : 800px;

$iota-objs-container-size-extra: (
  small   : 580px,
  large   : 1000px
);


@import 'node_modules/iotacss/objects/container';
