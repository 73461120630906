$project-component-padding-default: 10px 10px 60px;
$project-component-padding-at-sm: 100px 20px 140px;

$project-color-default: $color-white;
$project-color-chipotle: rgb(150, 113, 89);
$project-color-sequence: #a8a7b3;

$project-bgcolor-chipotle: rgb(245, 220, 196);
$project-bgcolor-sequence: rgb(59, 59, 61);
$project-bgcolor-chute: rgb(44, 175, 212);
$project-bgcolor-dorsia: rgb(249, 133, 96);
$project-bgcolor-shopify: #212b35;
$project-bgcolor-ikea: rgb(11, 90, 161);
$project-bgcolor-advocate: #35AB8F;
$project-bgcolor-hemster: #1f1e2c;

$project-bggradient-ikea: linear-gradient(135deg, #fcd000, #ffd45e);
$project-bggradient-iotacss: linear-gradient(135deg, #a248ec, #7549ee);

.c-project {
  padding: $project-component-padding-default;
  width: 100%;
  text-align: center;

  @include iota-breakpoint(sm) {
    padding: $project-component-padding-at-sm;
  }
}

.c-project--advocate {
  background: $project-bgcolor-advocate;
}
.c-project--hemster {
  background: $project-bgcolor-hemster;
}
.c-project--chipotle {
  background: $project-bgcolor-chipotle;
}
.c-project--sequence {
  background: $project-bgcolor-sequence;
}
.c-project--chute {
  background: $project-bgcolor-chute;
}
.c-project--dorsia {
  background: $project-bgcolor-dorsia;
}
.c-project--ikea {
  background: $project-bgcolor-ikea;
}
.c-project--shopify {
  background: $project-bgcolor-shopify;
}
.c-project--iotacss {
  background-image: $project-bggradient-iotacss;
}

.c-project__title {
  color: $project-color-default;

  .c-project--chipotle & {
    color: $project-color-chipotle;
  }
  .c-project--sequence & {
    color: $project-color-sequence;
  }
}

.c-project__description {
  color: $project-color-default;

  .c-project--chipotle & {
    color: $project-color-chipotle;
  }
  .c-project--sequence & {
    color: $project-color-sequence;
  }
}

.c-project__job-title {
  color: $project-color-default;

  .c-project--chipotle & {
    color: $project-color-chipotle;
  }
  .c-project--sequence & {
    color: $project-color-sequence;
  }
}

.c-project__asset {
  @include iota-breakpoint(xs) {
    width: 70%;
  }
}

.c-project__logo {
  width: 250px;
  height: 250px;
}
